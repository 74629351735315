<template>
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <perfect-scrollbar class="scroll-sidebar">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <bo-sidebar-menu
          :items="navigation"
        />
      </nav>
      <!-- End Sidebar navigation -->
    </perfect-scrollbar>
    <!-- End Sidebar scroll-->
  </aside>
</template>
<script>
import navigation from '@/navigation'
import BoSidebarMenu from '@/components/backend/BoSidebarMenu.vue'
import GlobalVue from '../../libs/Global.vue'

export default {
extends: GlobalVue,
  components: {
    BoSidebarMenu, 
  },
  mounted() {
    let body = document.querySelector('body')
    if(this.isDesktop){
      body.classList.remove('mini-sidebar')
    } else{
      body.classList.add('mini-sidebar')
    }
  },
  data() {
    return {
      linkWave: {
        color: "#777",
      },
      navigation,
    }
  },
}
</script>