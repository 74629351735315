<template>
  <li
    :class="{ active }"
  >
    <b-link v-wave="{color: '#777'}" :to="item.route" :class="{'active': active}" exact-active-class="active">
      <i
         v-if="item.icon"
        :class="item.icon"
      />
      <span class="hide-menu">{{ item.title }}</span>
    </b-link>
  </li>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    active() {
      return this.$route.name == this.item.route.name
    }
  }
}
</script>
