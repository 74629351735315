import _ from 'lodash'

const menu = [
  {
    title: 'Dashboard',
    route: {name: 'BoDashboard'},
    menu: 'BoDashboard',
    icon:  'icon-speedometer',
  },
  {
    cap: 'Transportation', relate: ['BoBookingTransport', 'BoNewSchedules', 'BoTransportOnGoing']
  },
  {
    title: 'Booking Transport',
    icon: 'ti-agenda',
    route: {name: 'BoBookingTransport'},
    menu: 'BoBookingTransport' 
  },
  {
    title: 'Schedules Transport',
    icon: 'ti-time',
    children: [
      {
        title: 'New Schedules',
        route: { name: 'BoNewSchedules' },
        menu: 'BoNewSchedules'
      },
      {
        title: 'On Going',
        route: { name: 'BoTransportOnGoing' },
        menu: 'BoTransportOnGoing'
      },
    ]
  },
  {
    title: 'Master Data',
    icon: 'ti-car',
    children: [
      {
        title: 'Vehicle',
        route: { name: 'BoVehicle' },
        menu: 'BoVehicle'
      },
      {
        title: 'Cashless Type',
        route: { name: 'BoCashless' },
        menu: 'BoCashless'
      },
    ]
  },
  {
    cap: 'Activity Task', relate: ['BoTaskActivity', 'BoTaskSetup', 'BoTaskEvaluation', 'BoTaskItem', 'BoHistoryTask']
  },
  {
    title: 'Activity Task',
    icon: 'ti-pin-alt',
    route: { name: 'BoTaskActivity' },
    menu: 'BoTaskActivity'
  },
  {
    title: 'Task Setup',
    icon: 'ti-pencil-alt',
    route: { name: 'BoTaskSetup' },
    menu: 'BoTaskSetup'
  },
  {
    title: 'Task Evaluation',
    icon: 'ti-blackboard',
    route: { name: 'BoTaskEvaluation' },
    menu: 'BoTaskEvaluation'
  },
  {
    title: 'Task History',
    icon: 'ti-archive',
    route: { name: 'BoHistoryTask' },
    menu: 'BoHistoryTask'
  },
  {
    title: 'Master Data',
    icon: 'ti-clipboard',
    children: [
      {
        title: 'Task Item',
        route: { name: 'BoTaskItem' },
        menu: 'BoTaskItem'
      },
    ]
  },
  {
    cap: 'Settings', relate: ['Users', 'BoUserLevel']
  },
  {
    title: 'User Management',
    icon: 'icon-user-follow',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users',
      },
      {
        title: 'User Level',
        route: {name: 'BoUserLevel'},
        menu: 'BoUserLevel'
      }
    ],
  },
  {
    title: 'Audit Trail',
    route: { name: 'BoAuditTrail' },
    icon:  'icon-check',
    menu: 'BoAuditTrail'
  }
]

let nav = menu

const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      })
    }

    return v
  })
}

export default nav