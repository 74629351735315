<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import Vue from 'vue'
import VWave from 'v-wave'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import vSelect from 'vue-select'
import LayoutBackoffice from './backend/LayoutBackoffice.vue'
import LayoutBackofficeEmpty from './backend/LayoutBackofficeEmpty.vue'

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: h => h('i', { class: 'fas fa-chevron-down' })
  },
  Deselect: {
    render: h => h('i', { class: 'fas fa-times' })
  }
})
vSelect.props.clearable = false

require('@/assets/backend/scss/style.scss')

Vue.use(PerfectScrollbar)
Vue.use(VWave)
Vue.component('v-select', vSelect)

export default {
  components: {
    LayoutBackoffice, LayoutBackofficeEmpty,
  },
  computed: {
    layout() {
      return this.$route.meta.layout === 'empty' ? 'layout-backoffice-empty' : 'layout-backoffice'
    }
  }  
}
</script>
