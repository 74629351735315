<template>
  <header class="topbar">
    <b-navbar type="dark" toggleable="md" class="top-navbar">
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
      <div class="navbar-header">
        <b-navbar-brand to="/">
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img src="/assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
            <!-- Light Logo icon -->
            <img src="/assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span class="ml-2" v-show="!isMobile">
            <!-- dark Logo text -->
            <img src="/assets/images/logo-text.png" style="height: 32px;" alt="homepage" class="dark-logo" />
            <!-- Light Logo text -->
            <img src="/assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
          </span>
        </b-navbar-brand>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse">
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <b-navbar-nav class="mr-auto">
          <b-nav-item @click.prevent="toggleSidebar" link-classes="toggle-nav" v-wave>
            <i class="icon-menu"></i>
          </b-nav-item>
        </b-navbar-nav>
        <!-- ============================================================== -->
        <!-- User profile and search -->
        <!-- ============================================================== -->
        <b-navbar-nav class="my-lg-0">

          <b-nav-item-dropdown v-wave class="u-pro notif-dd" toggle-class="text-decoration-none" ref="btnNotif">
            <template #button-content>
              <i class="ti-bell"></i>
              <div 
                class="notify"
                v-if="user.is_not_read == 'Y'"
              > 
                <span class="heartbit"></span> 
                <span class="point"></span> 
              </div>
            </template>
            <div class="mailbox animated">
              <ul>
                <li>
                  <div class="drop-title">Notification</div>
                </li>
                <li>
                  <div 
                    :class="{
                      'message-center':1, 
                      'notification-scroll':lengthArr(user.notifications), 
                      'notification-auto':!lengthArr(user.notifications)
                    }"
                  >                  
                    
                    <span v-if="lengthArr(user.notifications)">
                      <a
                        v-for="(v, k) in user.notifications" 
                        :key="k"
                        style="color: #000;"
                        :class="{'notread': v.an_is_read=='N'}"
                        href="javascript:;"
                        @click="doClickNotif(v)"
                      >
                        <div class="mail-content">
                          <span class="mail-desc">
                            {{v.an_description.limitWord(12)}}
                          </span> 
                          <small class="time d-block">
                            <template v-if="v.an_date">
                              {{ v.an_date | moment("DD MMMM YYYY") }}
                            </template>
                          </small> 
                        </div>
                      </a>
                    </span>
                    <p v-else class="text-center mt-3">
                      There's no notification yet
                    </p>

                  </div>
                </li>
                <li v-if="lengthArr(user.notifications)">
                  <a style="color:#777 !important;" class="nav-link text-center link link_notify" href="/notification" @click="closeDropdown()"> 
                    <strong>More Notifications</strong> <i class="fa fa-angle-right"></i> 
                  </a>
                </li>
              </ul>
            </div>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-wave class="u-pro" toggle-class="profile-pic">
            <template #button-content>
              <b-img width="30" rounded="circle" 
                :src="uploader(user.avatar)" 
              />
              <span class="mx-2">{{ user.fullName }}</span>
              <i class="fa fa-angle-down" />
            </template>
            <b-dropdown-item :to="{name:'BoAccountSetting'}"><i class="ti-settings mr-1"></i> Account Setting</b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="doLogout"><i class="fa fa-power-off mr-1"></i> Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </b-navbar>
  </header>
</template>

<style lang="scss" scoped>
.notification-auto{
	height: auto !important;
}

.notification-scroll{
	min-height: 100px !important;
	max-height: 300px !important;
}

.mailbox{
	min-width: 350px !important;
}

.notread {
  background: #0076BB;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-top: 1px solid #fff !important;

  &:hover {
    background: #004f7d !important;
  }
}
</style>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
export default {
  extends: GlobalVue,
  methods: {
    doClickNotif(data){
      if(data.an_is_read=='N'){
				Gen.apiRest(
					"/do/read-notif",
					{
						data:{ id:data.an_id }
					},
					'POST'
				).then(()=>{
					this.$refs.btnNotif.hide()
					data.an_is_read="Y"
					this.$router.push(data.an_link)
				})
			}else{
				this.$refs.btnNotif.hide()
				this.$router.push(data.an_link)
			}
    },
    closeDropdown(){
      this.$refs.btnNotif.hide()   
    },
    toggleSidebar() {
      this.$store.commit('toggleBoSidebar')
    }
  }
}
</script>