<script>
export default {
  name: 'BoNavCap',
  functional: true,
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h, { props, data }) {
    return h(
      'li',
      {
        ...data,
        staticClass: 'nav-small-cap',
      },
      '--- '+props.item.cap,
    )
  }
}
</script>